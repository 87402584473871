<template>
  <section>
    <div>
      <v-carousel hide-delimiters :cycle="true" :show-arrows="false" class="expanded" style="height: 500px">
        <v-carousel-item v-for="(image, index) in trip.images" :key="index">
          <v-parallax :src="image.url" style="height: 700px;"></v-parallax>
        </v-carousel-item>
        <v-overlay :absolute="true" :opacity="0.8" z-index="2">
          <div class="pa-10 text-center">
            <div class="goBold-4 pb-4">
              {{ trip.name }}
            </div>
          </div>
        </v-overlay>
      </v-carousel>
    </div>
    <v-container>
      <v-progress-circular v-if="isLoading" indeterminate color="white"></v-progress-circular>
      <v-row>
        <v-col xl="7" lg="8" md="7" sm="12" offset-xl="1">
          <v-breadcrumbs :items="breadcrumbs" divider="/" class="gothic-1 pt-0 pl-0">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :disabled="item.disabled"
                class="show-pointer"
                @click="$router.replace({ name: item.goTo })"
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <div class="hidden-sm-and-up" style="float:right;">
            <v-btn @click="scrollToPricing" color="primary">View Dates</v-btn>
          </div>
          <span class="text-center goBold-4">{{ trip.name }}</span>
          <div v-html="trip.content" class="my-6 main-content-view"></div>
        </v-col>
        <v-col xl="3" lg="4" md="5" sm="12" xs="12">
          <div class="sticky">
            <v-card v-if="selectedTripDate !== null" id="pricing">
              <v-card-text>
                <div v-if="trip.dates.length > 1">
                  <v-select
                    :items="trip.dates"
                    filled
                    item-text="id"
                    item-value="id"
                    v-model="cart.tripDateId"
                    :label="`${trip.name} Dates`"
                    @change="onDateSelection"
                    class="multipleDatesSelection"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="gothic-1">
                        {{ $moment(item.start).format('LL') }} to {{ $moment(item.end).format('LL') }}
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="gothic-1">
                              {{ $moment(item.start).format('LL') }} to {{ $moment(item.end).format('LL') }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="gothic-1">
                              {{ item.nights }} Nights
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </span>
                    </template>
                  </v-select>
                </div>
                <div v-else class="text-center font-weight-black goBold-2 black--text">
                  {{ $moment(selectedTripDate.start).format('ll') }} to {{ $moment(selectedTripDate.end).format('ll') }}
                </div>
                <div v-if="!trip.isFilled">
                  <div>
                    <div class="gothic-1 text-center black--text my-5">
                      {{ trip.subtitle }}
                    </div>
                    <div class="text-center">
                      <outlined-auto-button @click="doCallBusiness">
                        <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
                        <span class="ml-2">Call for questions</span>
                      </outlined-auto-button>
                    </div>
                  </div>
                  <div v-if="selectedTripDate.products.length >= 1" v-show="$store.getters.doGetSystemControlAllowPurchases">
                    <div class="subtitle-1 text-center mt-4">
                      Choose your trip options below
                    </div>
                    <div v-for="(productKey, indexB) in Object.keys(doCategorizeProducts(selectedTripDate.products))" :key="indexB" class="my-2 pa-4">
                      <div class="subtitle-1 font-weight-bold">{{ productKey }}</div>
                      <v-divider></v-divider>
                      <div v-for="(product, indexC) in doCategorizeProducts(selectedTripDate.products)[productKey]" :key="indexC">
                        <div class="inlineRow">
                          <div>
                            <div v-if="product.isRequired">
                              <v-checkbox off-icon="fa-regular fa-square" :value="true" v-model="product.isRequired" disabled></v-checkbox>
                            </div>
                            <div v-else>
                              <v-checkbox v-model="cart.items" off-icon="fa-regular fa-square" :value="product.id" :disabled="product.isRequired"></v-checkbox>
                            </div>
                          </div>

                          <div style="min-width: 140px;">
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <div>
                                      {{ product.name }}
                                    </div>
                                    <div class="caption red--text">
                                      {{ product.isRequired ? 'Required' : '' }}
                                    </div>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>

                          <div>
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <div class="title">
                                      ${{ product.cents / 100 }}
                                    </div>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </div>

                        <div class="productDescription">
                          {{ product.description }}
                        </div>
                      </div>
                    </div>
                    <v-btn block color="primary" @click="doAddToCart" class="mt-4" v-show="$store.getters.doGetSystemControlAllowPurchases">
                      <font-awesome-icon size="lg" :icon="['fas', 'cart-plus']" fixed-width class="mr-2"></font-awesome-icon>
                      Add to Cart - ${{ grandTotal }}
                    </v-btn>
                  </div>
                </div>
                <div v-else class="ma-4">
                  Unfortunately, all the spots for this trip have already been taken, and it is currently fully booked.
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <wave-effect></wave-effect>
  </section>
</template>

<script>
import OutlinedAutoButton from '@/components/outlinedAutoButton.vue'
import WaveEffect from '@/components/waveEffect.vue'

export default {
  name: 'viewTrip',
  components: { WaveEffect, OutlinedAutoButton },
  data: () => ({
    isLoading: false,
    breadcrumbs: [
      {
        text: 'Salt and Sea Scuba',
        disabled: false,
        goTo: 'home'
      },
      {
        text: 'Trips',
        disabled: false,
        goTo: 'trips'
      }
    ],
    selectedTripDate: null,
    cart: {
      tripDateId: null,
      items: []
    },
    trip: {
      id: null,
      dates: []
    },
    productGroupUuid: String()
  }),
  computed: {
    fullCart () {
      const fullCart = []

      this.selectedTripDate.products.forEach(product => {
        if (product.isRequired) {
          fullCart.push(product)
        } else if (this.cart.items.includes(product.id)) {
          fullCart.push(product)
        }
      })

      return fullCart
    },
    grandTotal () {
      let total = 0

      this.selectedTripDate.products.forEach(product => {
        if (product.isRequired) {
          total = total + product.cents
        } else if (this.cart.items.includes(product.id)) {
          total = total + product.cents
        }
      })

      return (total / 100)
    }
  },
  methods: {
    doLoad () {
      this.isLoading = true

      this.$services.trip.doGetById(this.trip.id).then(trip => {
        if (trip === null) {
          this.$router.replace({ name: 'home' })
        } else {
          this.trip = trip

          if (trip.dates.length >= 1) {
            this.selectedTripDate = trip.dates[0]
            this.cart.tripDateId = this.selectedTripDate.id
          }

          this.breadcrumbs.push({
            text: trip.name,
            disabled: true
          })

          this.doSetDocumentTitle(trip.name, true)
        }
      }).catch(error => {
        console.log(error)
      }).finally(_ => {
        this.$forceUpdate()
      })
    },
    doCategorizeProducts (products) {
      const categorizedProducts = {}

      products.forEach(product => {
        if (product.category.name in categorizedProducts) {
          categorizedProducts[product.category.name].push(product)
        } else {
          categorizedProducts[product.category.name] = [product]
        }
      })

      return categorizedProducts
    },
    onDateSelection () {
      this.selectedTripDate = this.trip.dates.filter(date => {
        if (date.id === this.cart.tripDateId) {
          return date
        }
      })[0]

      this.$forceUpdate()
    },
    doAddToCart () {
      const productGroup = {
        id: this.trip.id,
        uuid: this.productGroupUuid,
        title: this.trip.name,
        description: this.trip.subtitle,
        images: this.trip.images,
        type: 'trip',
        products: this.fullCart,
        properties: {
          tripDateId: this.cart.tripDateId
        }
      }

      this.$store.dispatch('doAddProductGroup', productGroup)

      this.productGroupUuid = this.$uuid.v4()
    },
    scrollToPricing () {
      this.$vuetify.goTo('#pricing', {
        duration: 300,
        offset: 100,
        easing: 'easeInOutCubic'
      })
    }
  },
  mounted () {
    this.trip.id = this.$route.params.id
    this.productGroupUuid = this.$uuid.v4()
    this.doLoad()
  }
}
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 75px;
}

.inlineRow {
  display: flex;
  flex-direction: row;
}

.productDescription {
  margin-top: -20px;
  margin-bottom: 10px;
}

.multipleDatesSelection {
  margin-bottom: -25px;
}

::v-deep .main-content-view img {
  width: 100%;
  height: 100%;
}
</style>
